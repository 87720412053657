.m-formfield {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 24px;

	&_input {
		position: relative;
		flex-grow: 1;

		.a-pulldown {
			flex: 1;
			@include sp {
				flex: unset;
				width: rem(80);
				&:last-child {
					flex-grow: 1;
				}
			}
		}
	}

	.a-text {
		flex: 0 0 142px;
		padding-right: 15px;
	}

	&-noMargin {
		margin-top: 0 !important;
	}

	&-block {
		display: block;
	}

	&-customerInfo {
		margin-top: 24px;
		.a-text {
			flex: 0 0 260px;
			@include sp {
				flex: 0 0 140px;
			}
		}
	}

	&-giftInfo {
		.a-text {
			flex: 0 0 178px;
		}

		input {
			max-width: 214px;
		}
	}

	&-giftInfoMT {
		.a-text {
			flex: 0 0 206px;
		}
	}

	&-draw {
		.a-text {
			flex: 0 0 225px;
		}
	}
}
