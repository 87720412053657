/* stylelint-disable prettier/prettier */
.t-selectBill {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &_list {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: auto;
  }

  &_item {
    flex: 1 0 50%;
    max-width: 50%;
    padding: rem(8);
  }

  &_preview {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100%;
    background-color: rgb(0, 0, 0);

    button {
      display: inline-block;
      width: rem(150);
      margin-top: rem(24);
      margin-bottom: rem(24);
    }

    .react-transform-wrapper {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      width: 100%;
      overflow: hidden;
      background-color: rgb(0, 0, 0);
    
      .react-transform-component {
        height: 100%;
        overflow: hidden;
    
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &_noti {
    align-self: center;
    width: 100%;
    font-size: rem(24);
    text-align: center;
  }

  &_capture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: rem(16);
    background: url('~assets/images/common/background.jpg') center/cover no-repeat;
    background-attachment: fixed;

    &_images {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      overflow-y: auto;
    }

    &_image {
      position: relative;
      width: 50%;
      padding: rem(8);
    }

    &_close {
      position: absolute;
      top: rem(16);
      right: rem(16);
    }

    &_controls {
      flex-shrink: 0;
    }

    &_flex {
      display: flex;
      justify-content: space-between;
      padding-right: rem(24);
      padding-left: rem(24);
    }

    label {
      button {
        pointer-events: none;
      }
    }

    &_submit {
      padding-right: rem(24);
      padding-left: rem(24);
      margin-top: rem(8);
      button {
        width: 100%;
      }
    }
  }

  &_inputCode {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100%;
    background-color: rgb(0, 0, 0);

    .a-textfield {
      width: calc(100% - 32px);
      margin-top: rem(24);
    }

    button {
      display: inline-block;
      width: rem(150);
      margin-top: rem(24);
      margin-bottom: rem(24);
    }

    .react-transform-wrapper {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      width: 100%;
      overflow: hidden;
      background-color: rgb(0, 0, 0);
    
      .react-transform-component {
        height: 100%;
        overflow: hidden;
    
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}