.a-text {
	$root: &;
	margin-bottom: 0;

	&-centered {
		text-align: center;
	}

	&-black {
		color: $COLOR-BLACK;
	}

	&-spanishBlue {
		color: $COLOR-SPANISH-BLUE;
	}

	&-charlestonGreen {
		color: $COLOR-CHARLESTON-GREEN;
	}

	&-yankeesBlue {
		color: $COLOR-YANKEES-BLUE;
	}

	&-18x26 {
		font-size: 18px;
		line-height: 26px;
	}

	&-white {
		color: $COLOR-WHITE;
	}

	// transform
	&-uppercase {
		text-transform: uppercase;
	}

	&-italic {
		font-style: italic;
	}

	&-stoke {
		-webkit-text-stroke: 0.75px $COLOR-WHITE;
	}

	&-center {
		text-align: center;
	}
}
