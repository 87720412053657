.a-button {
	$root: &;
	min-width: 155px;
	height: 45px;
	padding: rem(4);
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	cursor: pointer;
	background: linear-gradient(
		116.2deg,
		rgb(208, 147, 43) 7.28%,
		rgb(219, 168, 70) 10.22%,
		rgb(230, 189, 100) 13.46%,
		rgb(239, 206, 124) 16.77%,
		rgb(245, 219, 140) 20.14%,
		rgb(249, 227, 150) 23.6%,
		rgb(251, 229, 154) 27.28%,
		rgb(243, 214, 135) 32.14%,
		rgb(225, 179, 86) 42.17%,
		rgb(208, 147, 43) 51.44%,
		rgb(221, 172, 75) 57.09%,
		rgb(236, 202, 118) 64.16%,
		rgb(247, 221, 144) 69.56%,
		rgb(251, 229, 154) 72.62%,
		rgb(208, 147, 43) 93.03%
	);
	border: 0;
	border-radius: rem(999);
	outline: none;
	box-shadow: 0 4px 4px rgba($color: $COLOR-BLACK, $alpha: 0.25);
	appearance: none;
	transition: opacity 0.3s ease-in-out;

	&:hover {
		@include pcFixedContent {
			opacity: 0.7;
		}
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&_wrapper:not(.a-button-otp) {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding-right: rem(16);
		padding-left: rem(16);
		overflow: hidden;
		background: linear-gradient(
			359.42deg,
			rgb(59, 165, 88) 0.44%,
			rgb(17, 107, 56) 99.44%
		);
		border-radius: rem(999);

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 50%;
			content: "";
			background: linear-gradient(
				180deg,
				rgb(159, 184, 199) 1.73%,
				rgb(1, 1, 1) 100.84%
			);
			mix-blend-mode: screen;
			opacity: 0.8;

			#{$root}-otp &,
			#{$root}-textButton & {
				display: none;
			}
		}

		#{$root}-otp &,
		#{$root}-textButton & {
			overflow: unset;
			line-height: 20px;
			background: unset;
			border-radius: unset;
		}

		#{$root}-textButton & {
			color: rgb(0, 0, 0);
		}

		#{$root}-secondary & {
			background: linear-gradient(
				359.42deg,
				rgb(118, 131, 121) 0.44%,
				rgb(55, 61, 58) 99.44%
			);
		}
	}

	&-linearPrimary {
		color: rgb(130, 40, 87);
		background: linear-gradient(
			90deg,
			rgb(200, 147, 59) 0%,
			rgb(250, 229, 158) 18%,
			rgb(214, 170, 102) 42%,
			rgb(229, 194, 124) 48%,
			rgb(240, 212, 142) 55%,
			rgb(247, 223, 153) 62%,
			rgb(249, 227, 156) 70%,
			rgb(240, 214, 135) 74%,
			rgb(221, 188, 91) 85%,
			rgb(209, 172, 64) 93%,
			rgb(205, 166, 54) 98%,
			rgb(205, 166, 54) 100%
		);

		#{$root}_wrapper {
			background: none;

			&::before {
				display: none;
			}
		}
	}

	&-yellowLinear {
		color: rgb(130, 40, 87);
		background: linear-gradient(
			90deg,
			rgb(200, 147, 59) 0%,
			rgb(250, 229, 158) 18%,
			rgb(214, 170, 102) 42%,
			rgb(229, 194, 124) 48%,
			rgb(240, 212, 142) 55%,
			rgb(247, 223, 153) 62%,
			rgb(249, 227, 156) 70%,
			rgb(240, 214, 135) 74%,
			rgb(221, 188, 91) 85%,
			rgb(209, 172, 64) 93%,
			rgb(205, 166, 54) 98%,
			rgb(205, 166, 54) 100%
		) !important;
	}

	&-otp {
		height: 43px;
		min-height: 42px;
		padding: 4px 8px;
		text-transform: none;
		background: linear-gradient(
			90deg,
			rgba(59, 167, 88, 1) 0%,
			rgba(45, 147, 77, 1) 50%,
			rgba(17, 107, 56, 1) 100%
		);
		border-radius: 8px;

		@include sp {
			font-size: 10px;
			line-height: 17px;
		}
	}

	&-smallSize {
		min-width: 50px;
	}

	&-mediumSize {
		min-width: 125px;
	}

	&-fullWidth {
		width: 100%;
	}

	&-textButton {
		background: none;
		border-radius: 0;
		box-shadow: none;
	}

	&-secondary {
		background: none;
	}
}
